import { ReactNode, memo } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";

const OuterWrapper = styled.div`
  display: flex;
  flex: 1;
  min-height: 100%;
`;

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0 auto;
`;

const StyledContainer = styled(Container)`
  position: fixed;
  z-index: 10;
  padding: 50px 0 0 0;
  bottom: 0;
  top: 0;
  background: rgba(39, 39, 46, 0.97);
  max-width: none;
  margin: 0 auto;
`;

interface PageProps {
  children: ReactNode;
  className?: string;
  fixed?: boolean;
}

const Page = (props: PageProps) => {
  const { children, className, fixed = false } = props;

  const getInnerContent = () => {
    return (
      <OuterWrapper>
        <Wrapper fluid={fixed} className={className}>
          {children}
        </Wrapper>
      </OuterWrapper>
    );
  };

  if (fixed) {
    return <StyledContainer>{getInnerContent()}</StyledContainer>;
  }

  return getInnerContent();
};

export default memo(Page);
