import { RootState } from "../store";

export const tokenSelector = (state: RootState) => state.user.token;

export const creatingUserSelector = (state: RootState) =>
  state.user.creatingUser;

export const createdUserEmailSelector = (state: RootState) =>
  state.user.createdUserEmail;

export const verifyingUserSelector = (state: RootState) =>
  state.user.verifyingUser;

export const verifyUserSuccessSelector = (state: RootState) =>
  state.user.verifyUserSuccess;

export const verifyUserErrorSelector = (state: RootState) =>
  state.user.verifyUserError;

export const makingPaymentSelector = (state: RootState) =>
  state.user.makingPayment;

export const mnakePaymentSuccessSelector = (state: RootState) =>
  state.user.makePaymentSuccess;

export const makePaymentErrorSelector = (state: RootState) =>
  state.user.makePaymentError;

export const signingInSelector = (state: RootState) => state.user.signingIn;

export const signInErrorSelector = (state: RootState) => state.user.signInError;

export const loadingUserSelector = (state: RootState) => state.user.loadingUser;

export const userSelector = (state: RootState) => state.user.user;
