import { FC, ReactNode, memo } from "react";
import { Container } from "react-bootstrap";
import { IconProps } from "react-bootstrap-icons";
import { styled } from "styled-components";

const Wrapper = styled(Container)`
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 50px;
  flex-direction: column;

  @media only screen and (min-width: 576px) {
    margin-top: 40px;
    flex-direction: row;
    margin-bottom: 90px;
  }

  svg {
    background: var(--primary-color);
    color: white;
    width: 75px;
    height: 75px;
    padding: 10px;

    @media only screen and (min-width: 576px) {
      width: 120px;
      height: 120px;
      padding: 25px;
    }
  }
`;

const StyledH1 = styled.h1`
  font-size: var(--font-2);
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;

  @media only screen and (min-width: 576px) {
    text-align: left;
    margin-top: 0;
    margin-bottom: 30px;
    font-size: var(--font-3);
  }
`;

const Info = styled.span`
  font-size: var(--font-1);
  text-align: center;

  @media only screen and (min-width: 576px) {
    text-align: left;
    font-size: var(--font-2);
  }
`;

const Text = styled.div<{ $align: string }>`
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 576px) {
    ${(props) =>
      props.$align === "right" ? "padding-right: 50px;" : "padding-left: 50px;"}
  }
`;

interface FeatureProps {
  title: string;
  Icon: FC<IconProps>;
  children: ReactNode;
  align?: "left" | "right";
}

const Feature = (props: FeatureProps) => {
  const { title, Icon, children, align = "left" } = props;

  const generateText = () => {
    return (
      <Text $align={align}>
        <StyledH1>{title}</StyledH1>
        <Info>{children}</Info>
      </Text>
    );
  };

  const generateContent = () => {
    if (align === "right") {
      return (
        <>
          {generateText()}
          <Icon />
        </>
      );
    }

    return (
      <>
        <Icon />
        {generateText()}
      </>
    );
  };

  return <Wrapper>{generateContent()}</Wrapper>;
};

export default memo(Feature);
