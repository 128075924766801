import Page from "components/page/Page";
import { memo } from "react";
import { Helmet } from "react-helmet";
import InformationPage from "components/informationPage/InformationPage";
import { styled } from "styled-components";
import { Link } from "react-router-dom";

const Header = styled.h1`
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px 0;
  display: block;
  margin: 0;
`;

const Information = styled.span`
  display: block;
  margin: 20px 0;
`;

const TermsAndConditionsPage = () => {
  return (
    <>
      <Helmet>
        <title>MassGenerate - Terms & Conditions</title>
      </Helmet>
      <Page>
        <InformationPage title="Terms & Conditions" maxWidth={768}>
          <Header>Terms and Conditions for MassGenerate</Header>
          <Information>
            Welcome to MassGenerate! These terms and conditions outline the
            rules and regulations for using our website. By accessing and using
            MassGenerate, you accept and agree to comply with these terms. If
            you do not agree with any part of these terms, please refrain from
            using our website.
          </Information>
          <Header>1. Account Registration</Header>
          <Information>
            1.1 Eligibility: MassGenerate is available for use by individuals
            who are at least 18 years old. By using our website, you represent
            and warrant that you are at least 18 years of age.
          </Information>
          <Information>
            1.2 Account Information: Users may register an account with
            MassGenerate using their email address and password. You are
            responsible for maintaining the confidentiality of your account
            credentials and for all activities that occur under your account.
          </Information>
          <Information>
            1.3 User Content: When using MassGenerate to generate text content
            for SEO purposes, users may provide topics, keywords, and conversion
            goals. By submitting this information, you grant MassGenerate the
            right to use, reproduce, modify, adapt, publish, translate,
            distribute, and display the content for the purpose of providing the
            requested service.
          </Information>
          <Header>2. Purchase of Tokens</Header>
          <Information>
            2.1 Non-Refundable Tokens: Users may purchase non-refundable tokens
            via Credit Card (Stripe) to access the text generation service
            provided by MassGenerate. Tokens are non-refundable and
            non-transferable.
          </Information>
          <Information>
            2.2 Payment Processing: Payments are processed securely through
            Stripe. By making a purchase, you agree to abide by Stripe's terms
            of service and privacy policy.
          </Information>
          <Header>3. Use of Text Generation Service</Header>
          <Information>
            3.1 SEO Content Generation: MassGenerate uses ChatGPT to generate
            text content for SEO purposes based on the information provided by
            the user, including topics, keywords, and conversion goals.
          </Information>
          <Information>
            3.2 User Identifiable Information: MassGenerate does not share any
            user identifiable information apart from the information used to
            generate the requested content. User data is processed anonymously
            to ensure privacy and confidentiality.
          </Information>
          <Header>4. Google Analytics and Ads</Header>
          <Information>
            MassGenerate uses Google Analytics and Google Ads to gather insights
            into website traffic and user behavior. These services automatically
            collect certain non-personal information about your interactions
            with our site, such as your IP address, browser type, operating
            system, referring website, pages visited, and the date and time of
            your visit.
          </Information>
          <Header>5. Limitation of Liability</Header>
          <Information>
            To the maximum extent permitted by applicable law, MassGenerate, its
            officers, directors, employees, and agents shall not be liable for
            any indirect, incidental, consequential, or punitive damages,
            including but not limited to loss of profits, data, or use, arising
            out of or in connection with your use of our website or any services
            provided by MassGenerate.
          </Information>
          <Header>6. Modifications and Updates</Header>
          <Information>
            MassGenerate reserves the right to modify, update, or change these
            terms and conditions at any time. Users are encouraged to review the
            terms regularly. Continued use of MassGenerate after any
            modifications constitutes acceptance of the updated terms.
          </Information>
          <Header>7. Governing Law and Jurisdiction</Header>
          <Information>
            These terms and conditions shall be governed by and construed in
            accordance with the laws of Western Australia. Any disputes arising
            out of or relating to these terms shall be subject to the exclusive
            jurisdiction of the courts in Western Australia.
          </Information>
          <Header>8. Contact Us</Header>
          <Information>
            If you have any questions, concerns, or inquiries regarding these
            terms and conditions, please <Link to={"/contact"}>contact us</Link>
            .
          </Information>
          <Information>
            By using MassGenerate, you acknowledge that you have read,
            understood, and agreed to these terms and conditions.
          </Information>
        </InformationPage>
      </Page>
    </>
  );
};

export default memo(TermsAndConditionsPage);
