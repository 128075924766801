import { useAppDispatch, useAppSelector } from "hooks";
import { memo } from "react";
import { loadingUserSelector, userSelector } from "selectors/userSelectors";
import { RootState } from "store";
import PrimaryButton from "components/primaryButton/PrimaryButton";
import { Spinner } from "react-bootstrap";
import { styled } from "styled-components";
import { CCircleFill } from "react-bootstrap-icons";
import uiSlice from "reducers/uiSlice";

const CreditsButton = styled(PrimaryButton)`
  background: var(--field-color);
  border-color: var(--field-color);
  min-height: 25px;
  padding: 2px 10px;
  margin-right: 15px;
  font-size: var(--font-1);

  > svg {
    margin-right: 10px;
  }
`;

interface AvailbleCreditsProps {}

const AvailbleCredits = (props: AvailbleCreditsProps) => {
  const dispatch = useAppDispatch();

  const loadingUser = useAppSelector((state: RootState) =>
    loadingUserSelector(state)
  );

  const user = useAppSelector((state: RootState) => userSelector(state));

  const handleAddCreditsClick = () => {
    dispatch(uiSlice.actions.showAddCredits());
  };

  if (!user && !loadingUser) return null;

  return (
    <>
      <CreditsButton type={"button"} onClick={handleAddCreditsClick}>
        <CCircleFill />
        {loadingUser && <Spinner size="sm" />}
        {!loadingUser && user && <>{user.availableCredits}</>}
      </CreditsButton>
    </>
  );
};

export default memo(AvailbleCredits);
