import Label from "components/label/Label";
import PrimaryButton from "components/primaryButton/PrimaryButton";
import TextInput from "components/textInput/TextInput";
import { Formik } from "formik";
import { useAppDispatch, useAppSelector } from "hooks";
import { memo } from "react";
import { Form, Spinner } from "react-bootstrap";
import styled from "styled-components";
import * as Yup from "yup";
import { X } from "react-bootstrap-icons";
import uiSlice from "reducers/uiSlice";
import userSlice from "reducers/userSlice";
import { RootState } from "store";
import {
  createdUserEmailSelector,
  creatingUserSelector,
} from "selectors/userSelectors";
import Scrollbars from "rc-scrollbars";
import { Link as ReactLink } from "react-router-dom";

const Wrapper = styled.div`
  width: 400px;
  background: var(--secondary-bg);
  padding: 20px 30px;
  position: relative;

  @media only screen and (max-width: 992px) {
    width: 300px;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    min-height: 50%;
  }
`;

const StyledHeader = styled.h1`
  font-size: var(--font-3);
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  > span {
    flex: 1;
  }
`;

const CloseButton = styled(PrimaryButton)`
  width: 30px;
  min-height: 30px;
  padding: 0;
`;

const Info = styled.span`
  margin-bottom: 40px;
  display: block;
`;

const Notice = styled.span`
  margin-top: 40px;
  display: block;
`;

const CreateAccountButton = styled(PrimaryButton)`
  margin-top: 15px;
`;

const TermsAgree = styled(Form.Check)<{ $hasError: boolean | undefined }>`
  margin-top: 30px;

  a {
    color: var(--bs-link-hover-color-rgb);
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  ${(props) =>
    props.$hasError
      ? "input { border-color: red; } label, a { color: red; } a { text-decoration: underline;}"
      : null}
`;

const Scrollable = styled.div`
  position: absolute;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;

  .rc-scrollbars-view {
    padding: 0 30px 30px 30px;
  }
`;

interface SignUpSideMenuProps {
  contentId: string;
}

const SignUpSideMenu = (props: SignUpSideMenuProps) => {
  const { contentId } = props;

  const dispatch = useAppDispatch();

  const creatingUser = useAppSelector((state: RootState) =>
    creatingUserSelector(state)
  );

  const createdUserEmail = useAppSelector((state: RootState) =>
    createdUserEmailSelector(state)
  );

  const schema = Yup.object({
    email: Yup.string()
      .email("A valid email is required")
      .required("Email is a required field"),
    password: Yup.string().required("Password is a required field"),
    agree: Yup.string()
      .required()
      .oneOf(
        ["true"],
        "You must read and agree to the Terms and Conditions and Privacy Policy"
      ),
  });

  const handleSubmit = (values: any) => {
    dispatch(userSlice.actions.createUser({ ...values, contentId }));
  };

  const handleClose = () => {
    dispatch(uiSlice.actions.hideSignUp());
  };

  return (
    <Wrapper>
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          email: createdUserEmail ?? "",
          password: "",
          agree: "",
        }}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit} autoComplete="off">
            <StyledHeader>
              <span>Join For Free</span>
              {createdUserEmail === null && (
                <CloseButton onClick={handleClose}>
                  <X size={20} />
                </CloseButton>
              )}
            </StyledHeader>
            <Scrollable>
              <Scrollbars>
                <Info>
                  Get {process.env["REACT_APP_FREE_CREDITS"]} credits for free
                  when you sign up
                </Info>
                <Form.Group className="mb-3" controlId="email">
                  <Label>Email</Label>
                  <TextInput
                    spellCheck={false}
                    disabled={createdUserEmail !== null}
                    className="form-control"
                    hasError={
                      props.touched.email && props.errors.email !== undefined
                    }
                    value={props.values.email}
                    onChange={props.handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="password">
                  <Label>Password</Label>
                  <TextInput
                    type="password"
                    disabled={createdUserEmail !== null}
                    className="form-control"
                    hasError={
                      props.touched.password &&
                      props.errors.password !== undefined
                    }
                    value={props.values.password}
                    onChange={props.handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="agree">
                  <TermsAgree
                    disabled={createdUserEmail !== null}
                    $hasError={
                      props.touched.agree && props.errors.agree !== undefined
                    }
                    onChange={props.handleChange}
                    id="agree"
                    name="agree"
                    value={props.values.agree}
                    label={
                      <>
                        I have read and agree to the{" "}
                        <ReactLink target="_blank" to={"/terms-and-conditions"}>
                          Terms and Conditions
                        </ReactLink>{" "}
                        and{" "}
                        <ReactLink target="_blank" to={"/privacy-policy"}>
                          Privacy Policy
                        </ReactLink>{" "}
                      </>
                    }
                  />
                </Form.Group>

                {createdUserEmail && (
                  <Notice>
                    A verification email has been sent to {createdUserEmail}{" "}
                    with instructions to finish setting up your account.
                  </Notice>
                )}

                {createdUserEmail === null && (
                  <CreateAccountButton disabled={creatingUser}>
                    {!creatingUser && <span>Create Account</span>}
                    {creatingUser && <Spinner size="sm" />}
                  </CreateAccountButton>
                )}
              </Scrollbars>
            </Scrollable>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default memo(SignUpSideMenu);
