import { memo, ReactNode } from "react";
import { Spinner } from "react-bootstrap";
import { ExclamationTriangleFill } from "react-bootstrap-icons";
import styled from "styled-components";

interface NoticeProps {
  children: ReactNode;
  type: "error" | "busy";
}

const Wrapper = styled.div<{ $error: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  flex-direction: column;

  > div {
    margin-bottom: 10px;
  }

  > svg {
    ${(props) => (props.$error ? "color: #ffa500;" : null)}
    font-size: 40px;
    margin-bottom: 10px;
  }
`;

const Notice = (props: NoticeProps) => {
  const { children, type } = props;

  return (
    <Wrapper $error={type === "error"}>
      {type === "error" && <ExclamationTriangleFill />}
      {type === "busy" && <Spinner />}
      {children}
    </Wrapper>
  );
};

export default memo(Notice);
