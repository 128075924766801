import { memo } from "react";
import { Form } from "react-bootstrap";
import { FormLabelOwnProps } from "react-bootstrap/esm/FormLabel";
import styled from "styled-components";

interface LabelProps extends FormLabelOwnProps {}

const StyledLabel = styled(Form.Label)`
  color: var(--bs-heading-color);
`;

const Label = (props: LabelProps) => {
  const { children, className, htmlFor } = props;

  return (
    <StyledLabel for={htmlFor} className={className}>
      {children}
    </StyledLabel>
  );
};

export default memo(Label);
