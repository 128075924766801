import PrimaryButton from "components/primaryButton/PrimaryButton";
import TextInput from "components/textInput/TextInput";
import { memo, useState } from "react";
import { Plus, Dash } from "react-bootstrap-icons";
import styled from "styled-components";

type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

interface TextInputListProps {
  max?: number;
  hasError: boolean | undefined;
  placeholder?: string | undefined;
  textarea?: boolean;
  values: string[];
  onChange?: React.ChangeEventHandler<FormControlElement>;
  onKeyUp?: React.KeyboardEventHandler<FormControlElement>;
  className?: string;
  onRemove: (index: number) => void;
  onAdd: () => void;
  name?: string;
  id?: string;
  disabled?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    cursor: pointer;
    margin-left: 10px;
  }
`;

const ControlWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const StyledTextInput = styled(TextInput)`
  flex: 1;
`;

const StyledControlButton = styled(PrimaryButton)<{ fill?: boolean }>`
  ${(props) => (props.fill !== true ? "margin-left: 10px; width: 40px;" : null)}
  padding-left: 0;
  padding-right: 0;
  min-height: 40px;

  > svg {
    margin: 0;
    font-size: var(--font-2);
  }
`;

const TextInputList = (props: TextInputListProps) => {
  const {
    max,
    hasError,
    placeholder,
    textarea,
    className,
    name,
    id,
    disabled,
    onChange,
    onKeyUp,
    onRemove,
    onAdd,
    values,
  } = props;

  const [autoFocus, setAutoFocus] = useState(false);

  const handleAddClick = () => {
    onAdd();
    setAutoFocus(true);
  };

  const handleRemoveClick = (index: number) => {
    onRemove(index);
  };

  return (
    <Wrapper>
      {values.map((value, index) => {
        return (
          <ControlWrapper key={index}>
            <StyledTextInput
              disabled={disabled}
              name={`${name}.${index}`}
              id={`${id}.${index}`}
              className={className}
              textarea={textarea}
              placeholder={placeholder}
              hasError={hasError}
              value={value?.toUpperCase()}
              onChange={onChange}
              onKeyUp={onKeyUp}
              autoFocus={autoFocus}
            />
            {values.length > 1 && (
              <StyledControlButton
                type="button"
                onClick={() => handleRemoveClick(index)}
              >
                <Dash />
              </StyledControlButton>
            )}
          </ControlWrapper>
        );
      })}
      {!max ||
        (values.length < max && (
          <StyledControlButton fill={true} onClick={handleAddClick}>
            <Plus />
          </StyledControlButton>
        ))}
    </Wrapper>
  );
};

export default memo(TextInputList);
