import { RootState } from "../store";

export const showingTopMenuSelector = (state: RootState) =>
  state.ui.showingTopMenu;

export const showingAddCreditsSelector = (state: RootState) =>
  state.ui.showingAddCredits;

export const showingSignUpSelector = (state: RootState) =>
  state.ui.showingSignUp;

export const showingSignInMenuSelector = (state: RootState) =>
  state.ui.showingSignInMenu;
