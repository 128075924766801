import { useAppDispatch, useAppSelector } from "hooks";
import { Container } from "react-bootstrap";
import { showingTopMenuSelector } from "selectors/uiSelectors";
import { tokenSelector } from "selectors/userSelectors";
import { RootState } from "store";
import styled from "styled-components";
import { Link as ReactLink, useLocation } from "react-router-dom";
import { useEffect } from "react";
import uiSlice from "reducers/uiSlice";
import { Scrollbars } from "rc-scrollbars";
import userSlice from "reducers/userSlice";

const StyledContainer = styled(Container)`
  position: fixed;
  z-index: 10;
  padding-top: 50px;
  bottom: 0;
  top: 0;
  background: rgba(39, 39, 46, 0.97);
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
`;

const StyledLink = styled(ReactLink)`
  border: solid 1px rgba(255, 255, 255, 0.25);
  text-align: center;
  text-decoration: none;
  padding: 20px 40px;
  margin-bottom: 50px;
  font-size: var(--font-3);
  &:hover {
    color: white;
    border-color: white;
  }

  @media only screen and (max-width: 575px) {
    font-size: var(--font-2);
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopMenu = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const showingTopMenu = useAppSelector((state: RootState) =>
    showingTopMenuSelector(state)
  );

  const handleSignOut = () => {
    dispatch(userSlice.actions.signOut());
  };

  useEffect(() => {
    if (!showingTopMenu) {
      return;
    }
    dispatch(uiSlice.actions.hideTopMenu());
  }, [dispatch, location]);

  const token = useAppSelector((state: RootState) => tokenSelector(state));

  if (!showingTopMenu) return null;

  return (
    <StyledContainer fluid={true}>
      <Scrollbars>
        <Wrapper>
          <MenuWrapper>
            {!token && <StyledLink to={""}>Home</StyledLink>}

            <StyledLink to={"dashboard"}>
              {!token ? "Sign In" : "Dashboard"}
            </StyledLink>

            {token && (
              <StyledLink to="" onClick={handleSignOut}>
                Sign Out
              </StyledLink>
            )}

            <StyledLink to={"contact"}>Contact</StyledLink>

            <StyledLink to={"terms-and-conditions"}>
              Terms & Conditions
            </StyledLink>

            <StyledLink to={"privacy-policy"}>Privacy Policy</StyledLink>
          </MenuWrapper>
        </Wrapper>
      </Scrollbars>
    </StyledContainer>
  );
};

export default TopMenu;
