import { memo } from "react";
import { Form, FormControlProps } from "react-bootstrap";
import styled from "styled-components";

interface TextInputProps extends FormControlProps {
  hasError?: boolean | undefined;
  placeholder?: string | undefined;
  textarea?: boolean;
}

const StyledText = styled(Form.Control)<{ $hasError: boolean | undefined }>`
  border-color: ${(props: any) =>
    props.$hasError ? "red !important" : "var(--field-color)"};
  background-color: var(--field-color);

  &:focus {
    border-color: var(--bs-link-color-rgb);
    background-color: var(--field-color);
    box-shadow: none;
  }

  &:disabled {
    background-color: var(--secondary-bg);
    opacity: 0.8;
  }
`;

const TextInput = (props: TextInputProps) => {
  const {
    hasError,
    value,
    onChange,
    placeholder = "",
    textarea,
    className,
    name,
    id,
    disabled,
    type,
    spellCheck,
    onKeyUp,
    autoFocus,
  } = props;

  return (
    <StyledText
      spellCheck={spellCheck}
      disabled={disabled}
      id={id}
      name={name}
      className={className}
      as={textarea ? "textarea" : ""}
      placeholder={placeholder}
      $hasError={hasError}
      value={value}
      onChange={onChange}
      type={type}
      onKeyUp={onKeyUp}
      autoFocus={autoFocus}
    />
  );
};

export default memo(TextInput);
