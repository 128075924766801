import { createSlice } from "@reduxjs/toolkit";

interface UiState {
  showingTopMenu: boolean;
  showingAddCredits: boolean;
  showingSignInMenu: boolean;
  showingSignUp: boolean;
}

const initialState: UiState = {
  showingTopMenu: false,
  showingAddCredits: false,
  showingSignInMenu: false,
  showingSignUp: false,
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    showTopMenu: (state: UiState) => {
      state.showingTopMenu = true;
      state.showingSignInMenu = false;
      state.showingAddCredits = false;
    },
    hideTopMenu: (state: UiState) => {
      state.showingTopMenu = false;
    },
    showAddCredits: (state: UiState) => {
      state.showingAddCredits = true;
    },
    hideAddCredits: (state: UiState) => {
      state.showingAddCredits = false;
    },
    showSignUp: (state: UiState) => {
      state.showingSignUp = true;
    },
    hideSignUp: (state: UiState) => {
      state.showingSignUp = false;
    },
    showSignInMenu: (state: UiState) => {
      state.showingSignInMenu = true;
    },
    hideSignInMenu: (state: UiState) => {
      state.showingSignInMenu = false;
    },
  },
});

export const {} = uiSlice.actions;

export default uiSlice;
