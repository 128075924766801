import { useAppDispatch, useAppSelector } from "hooks";
import { Alert, Form, Spinner } from "react-bootstrap";
import { showingSignInMenuSelector } from "selectors/uiSelectors";
import {
  signInErrorSelector,
  signingInSelector,
  tokenSelector,
} from "selectors/userSelectors";
import { RootState } from "store";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import uiSlice from "reducers/uiSlice";
import InformationPage from "components/informationPage/InformationPage";
import Page from "components/page/Page";
import { Formik } from "formik";
import * as Yup from "yup";
import Label from "components/label/Label";
import TextInput from "components/textInput/TextInput";
import PrimaryButton from "components/primaryButton/PrimaryButton";
import userSlice from "reducers/userSlice";

const StyledForm = styled(Form)`
  padding-top: 5px;
  padding-bottom: 15px;
`;

const TopMenu = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const showingSignInMenu = useAppSelector((state: RootState) =>
    showingSignInMenuSelector(state)
  );

  const signInError = useAppSelector((state: RootState) =>
    signInErrorSelector(state)
  );

  const signingIn = useAppSelector((state: RootState) =>
    signingInSelector(state)
  );

  const token = useAppSelector((state: RootState) => tokenSelector(state));

  useEffect(() => {
    if (showingSignInMenu) {
      dispatch(userSlice.actions.clearSignInFailure());
    }
  }, [dispatch, location]);

  useEffect(() => {
    if (token !== null) {
      dispatch(uiSlice.actions.hideSignInMenu());
    }
  }, [dispatch, token]);

  const schema = Yup.object({
    email: Yup.string()
      .email("A valid email is required")
      .required("Email is a required field"),
    password: Yup.string().required("Password is a required field"),
  });

  const handleSubmit = (values: any) => {
    dispatch(userSlice.actions.signIn(values));
  };

  if (!showingSignInMenu) return null;

  return (
    <Page fixed={true}>
      <InformationPage maxWidth={400} title="Sign In">
        <Formik
          onSubmit={handleSubmit}
          validationSchema={schema}
          initialValues={{
            email: "",
            password: "",
          }}
        >
          {(props) => (
            <StyledForm onSubmit={props.handleSubmit} autoComplete="off">
              <Form.Group className="mb-3" controlId="email">
                <Label>Email address</Label>
                <TextInput
                  hasError={
                    props.touched.email && props.errors.email !== undefined
                  }
                  value={props.values.email}
                  onChange={props.handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-4" controlId="password">
                <Label>Password</Label>
                <TextInput
                  hasError={
                    props.touched.password &&
                    props.errors.password !== undefined
                  }
                  type="password"
                  value={props.values.password}
                  onChange={props.handleChange}
                />
              </Form.Group>
              {signInError && <Alert variant="danger">{signInError}</Alert>}
              <PrimaryButton disabled={signingIn}>
                {!signingIn && <span>Sign In</span>}
                {signingIn && <Spinner size="sm" />}
              </PrimaryButton>
            </StyledForm>
          )}
        </Formik>
      </InformationPage>
    </Page>
  );
};

export default TopMenu;
