import { memo } from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";
import LoadContentResult from "types/loadContentResult";
import Scrollbars from "rc-scrollbars";

const Wrapper = styled.div`
  flex: 1;
`;

const Scrollable = styled.div`
  border-top: solid 1px var(--secondary-bg);
  display: flex;
  align-items: center;
  min-height: 100%;
  justify-content: center;
`;
const Preview = styled.div`
  background: var(--secondary-bg);
  padding: 30px 50px;
  margin: 30px 100px;
  @media only screen and (max-width: 992px) {
    margin-left: 30px;
    margin-right: 30px;
  }
  @media only screen and (max-width: 768px) {
    padding: 15px 25px;
    margin: 0;
  }
`;

const DesktopInfo = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
const MobileInfo = styled.div`
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

interface CreatePreviewProps {
  showingSignUp?: boolean | undefined;
  isLoading?: boolean;
  content?: LoadContentResult | null;
}

const CreatePreview = (props: CreatePreviewProps) => {
  const { showingSignUp = false, isLoading = false, content } = props;

  return (
    <Wrapper>
      <Scrollbars>
        <Scrollable>
          <Preview>
            {isLoading && <Spinner />}
            {!isLoading && !showingSignUp && !content?.content && (
              <>
                <DesktopInfo>Enter some info to the left</DesktopInfo>
                <MobileInfo>Enter some info above</MobileInfo>
              </>
            )}
            {!isLoading && showingSignUp && <>Sign up to continue</>}
            {!isLoading && !showingSignUp && content?.content}
          </Preview>
        </Scrollable>
      </Scrollbars>
    </Wrapper>
  );
};

export default memo(CreatePreview);
