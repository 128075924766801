import { useAppDispatch, useAppSelector } from "hooks";
import { memo, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import contentSlice from "reducers/contentSlice";
import {
  allContentSelector,
  loadingAllContentSelector,
} from "selectors/contentSelectors";
import { RootState } from "store";
import styled from "styled-components";
import ContentBase from "types/contentBase";
import { PencilFill } from "react-bootstrap-icons";
import PrimaryButton from "components/primaryButton/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { tokenSelector } from "selectors/userSelectors";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  padding: 0 15px;
`;

const Content = styled.div`
  background: var(--secondary-bg);
  width: 100%;
  margin-bottom: 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;

  > h2 {
    display: flex;
    padding: 20px 25px;
    font-size: var(--font-2);
    margin: 0;
    flex: 1;
  }
`;

const Keywords = styled.div`
  border-top: solid 1px var(--field-color);
  display: flex;
  padding: 20px 25px;
  > span {
    margin: 0 20px 0 0;
    background-color: var(--field-color);
    font-size: var(--font-1);
    padding: 10px 15px;
  }
`;

const StyledControlButton = styled(PrimaryButton)`
  margin-right: 10px;
  padding-left: 0;
  padding-right: 0;
  min-height: 40px;
  width: 40px;

  > svg {
    margin: 0;
    font-size: var(--font-2);
  }
`;

const Preview = styled.div`
  background: var(--secondary-bg);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
`;

const ContentList = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const token = useAppSelector((state: RootState) => tokenSelector(state));

  useEffect(() => {
    if (!token) return;
    dispatch(contentSlice.actions.loadAllContent());
  }, [dispatch, token]);

  const loadingAllContent = useAppSelector((state: RootState) =>
    loadingAllContentSelector(state)
  );

  const allContent = useAppSelector((state: RootState) =>
    allContentSelector(state)
  );

  const handleEditClick = (contentId: string) => {
    navigate(`/create/${contentId}`);
  };

  return (
    <Wrapper>
      {loadingAllContent && <Spinner />}
      {!loadingAllContent &&
        allContent &&
        allContent.map((c: ContentBase) => (
          <Content>
            <Header>
              <h2>{c.topic ?? "untitled"}</h2>
              <StyledControlButton onClick={() => handleEditClick(c.contentId)}>
                <PencilFill />
              </StyledControlButton>
            </Header>
            {c.keywords.length > 0 && (
              <Keywords>
                {c.keywords.map((k) => (
                  <span>{k}</span>
                ))}
              </Keywords>
            )}
          </Content>
        ))}
      {!loadingAllContent && allContent && allContent.length === 0 && (
        <Preview>No Content Yet</Preview>
      )}
    </Wrapper>
  );
};

export default memo(ContentList);
