import { configureStore, Tuple } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import uiSlice from "reducers/uiSlice";

// @ts-ignore
import { reducer as toastrReducer } from "react-redux-toastr";
import userSlice from "reducers/userSlice";
import emailSlice from "reducers/emailSlice";
import emailSagas from "sagas/emailSagas";
import userSagas from "sagas/userSagas";
import contentSlice from "reducers/contentSlice";
import contentSagas from "sagas/contentSagas";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    ui: uiSlice.reducer,
    user: userSlice.reducer,
    email: emailSlice.reducer,
    content: contentSlice.reducer,
    toastr: toastrReducer,
  },
  // @ts-ignore
  middleware: () => new Tuple(sagaMiddleware),
});

sagaMiddleware.run(emailSagas);
sagaMiddleware.run(userSagas);
sagaMiddleware.run(contentSagas);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
