import { memo, useEffect } from "react";
import Page from "components/page/Page";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import CreateSideMenu from "components/createSideMenu/CreateSideMenu";
import CreatePreview from "components/createPreview/CreatePreview";
import { styled } from "styled-components";
import { useAppDispatch, useAppSelector } from "hooks";
import { showingSignUpSelector } from "selectors/uiSelectors";
import { RootState } from "store";
import SignUpSideMenu from "components/signUpSideMenu/SignUpSideMenu";
import uiSlice from "reducers/uiSlice";
import contentSlice from "reducers/contentSlice";
import {
  contentSelector,
  loadingContentSelector,
} from "selectors/contentSelectors";

const StyledPage = styled(Page)`
  flex: 1;
  position: relative;
  flex-direction: row;

  @media only screen and (max-width: 768px) {
    flex-direction: column !important;
  }
`;

const HomePage = () => {
  const dispatch = useAppDispatch();

  const { contentId } = useParams();

  const showingSignUp = useAppSelector((state: RootState) =>
    showingSignUpSelector(state)
  );

  const content = useAppSelector((state: RootState) => contentSelector(state));
  const loadingContent = useAppSelector((state: RootState) =>
    loadingContentSelector(state)
  );

  useEffect(() => {
    if (contentId && content?.contentId !== contentId) {
      dispatch(contentSlice.actions.loadContent({ contentId: contentId }));
    }
  }, [content, contentId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(uiSlice.actions.hideSignUp());
      dispatch(contentSlice.actions.clearContent());
    };
  }, [dispatch]);

  if (!contentId) return;

  return (
    <>
      <Helmet>
        <title>MassGenerate - Create Content</title>‍
      </Helmet>
      <StyledPage fixed={true}>
        {showingSignUp && <SignUpSideMenu contentId={contentId} />}
        {!showingSignUp && (
          <CreateSideMenu contentId={contentId} content={content} />
        )}
        <CreatePreview
          showingSignUp={showingSignUp}
          isLoading={loadingContent}
          content={content}
        />
      </StyledPage>
    </>
  );
};

export default memo(HomePage);
