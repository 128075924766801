// @ts-ignore
import { toastr } from "react-redux-toastr";

import { put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import userSlice, {
  createUserFailure,
  createUserSuccess,
  loadUserFailure,
  loadUserSuccess,
  makePaymentFailure,
  makePaymentSuccess,
  signInFailure,
  signInSuccess,
  verifyUserFailure,
  verifyUserSuccess,
} from "reducers/userSlice";
import HttpResponse from "types/httpResponse";
import { PayloadAction } from "@reduxjs/toolkit";
import CreateUserResult from "types/createUserResult";
import CreateUserRequest from "types/createUserRequest";
import VerifyUserRequest from "types/verifyUserRequest";
import SignInRequest from "types/signInRequest";
import SignInResult from "types/signInResult";
import User from "types/user";
import MakePaymentRequest from "types/makePaymentRequest";

function* createUserSaga(action: PayloadAction<CreateUserRequest>) {
  try {
    const response: HttpResponse<CreateUserResult> = yield axios.post(
      `/user`,
      action.payload
    );
    yield put(createUserSuccess(response.data));
  } catch (error: any) {
    toastr.error("Error Creating User", error.message);
    yield put(createUserFailure(error.message));
  }
}

function* signInSaga(action: PayloadAction<SignInRequest>) {
  try {
    const response: HttpResponse<SignInResult> = yield axios.post(
      `/user/authenticate`,
      action.payload
    );
    yield put(signInSuccess(response.data));
  } catch (error: any) {
    yield put(signInFailure(error.message));
  }
}

function* verifyUserSaga(action: PayloadAction<VerifyUserRequest>) {
  try {
    yield axios.post(`/user/verify`, action.payload);
    yield put(verifyUserSuccess());
  } catch (error: any) {
    yield put(verifyUserFailure(error.message));
  }
}

function* loadUserSaga() {
  try {
    const response: HttpResponse<User> = yield axios.get(`/user`);
    yield put(loadUserSuccess(response.data));
  } catch (error: any) {
    yield put(loadUserFailure(error.message));
  }
}

function* makePaymentSaga(action: PayloadAction<MakePaymentRequest>) {
  try {
    yield axios.post(`/user/payment`, action.payload);
    yield put(makePaymentSuccess());
    toastr.success(`${action.payload.creditsToPurchase} credits purchased`);
  } catch (error: any) {
    yield put(makePaymentFailure(error.message));
  }
}

function* userSaga() {
  yield takeLatest(userSlice.actions.createUser, createUserSaga);
  yield takeLatest(userSlice.actions.verifyUser, verifyUserSaga);
  yield takeLatest(userSlice.actions.signIn, signInSaga);
  yield takeLatest(userSlice.actions.loadUser, loadUserSaga);
  yield takeLatest(userSlice.actions.makePayment, makePaymentSaga);
}

export default userSaga;
