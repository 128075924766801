import Page from "components/page/Page";
import { memo } from "react";
import { Helmet } from "react-helmet";
import InformationPage from "components/informationPage/InformationPage";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Header = styled.h1`
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px 0;
  display: block;
  margin: 0;
`;

const Information = styled.span`
  display: block;
  margin: 20px 0;
`;

const PrivacyPolicyPage = () => {
  return (
    <>
      <Helmet>
        <title>MassGenerate - Privacy Policy</title>
      </Helmet>
      <Page>
        <InformationPage title="Privacy Policy" maxWidth={768}>
          <Header>Privacy Policy for MassGenerate</Header>
          <Information>
            At MassGenerate, we are committed to protecting your privacy and
            ensuring the security of your personal information. This privacy
            policy explains how we collect, use, and handle any information that
            you provide while using our website. Please read this policy
            carefully to understand our practices regarding your data.
          </Information>

          <Header>1. Information Collection</Header>

          <Information>
            1.1 Account Registration: When you register an account with
            MassGenerate, we collect your email address and password. This
            information is used solely for the purpose of managing your account
            and providing access to our services.
          </Information>

          <Information>
            1.2 Payment Information: If you purchase non-refundable tokens via
            Credit Card (Stripe), we collect payment information such as your
            credit card details. This information is securely processed by our
            payment processor, Stripe, and is not stored on our servers.
          </Information>

          <Information>
            1.3 User Generated Content: To generate text content for SEO
            purposes, users may provide topics, keywords, and conversion goals.
            This information is used to provide the requested service and is not
            shared with any third parties.
          </Information>

          <Header>2. Use of Information</Header>

          <Information>
            2.1 Service Provision: We use the information collected to provide
            you with access to our text generation service. This includes
            generating text content based on the topics, keywords, and
            conversion goals provided by the user. data.
          </Information>

          <Information>
            2.2 Account Management: Your email address and password are used for
            account management purposes, such as account verification, password
            resets, and communication regarding your account.
          </Information>

          <Header>3. Data Sharing</Header>

          <Information>
            3.1 Third-Party Services: MassGenerate may use third-party services,
            such as Stripe for payment processing and Google Analytics for
            website analytics. These services may collect certain non-personal
            information about your interactions with our website, as described
            in their respective privacy policies.
          </Information>

          <Information>
            3.2 User Anonymity: MassGenerate does not share any user
            identifiable information apart from the information used to generate
            the requested content. User data is processed anonymously to ensure
            privacy and confidentiality.
          </Information>

          <Header>4. Data Security</Header>

          <Information>
            4.1 Security Measures: We take reasonable measures to protect the
            security and integrity of your personal information. This includes
            implementing appropriate technical and organizational measures to
            prevent unauthorized access, disclosure, alteration, or destruction
            of data.
          </Information>

          <Information>
            4.2 SSL Encryption: Our website uses SSL encryption to secure data
            transmission between your browser and our servers, ensuring that
            your information is protected during transmission.
          </Information>

          <Header>5. Google Analytics and Ads</Header>

          <Information>
            MassGenerate uses Google Analytics and Google Ads to gather insights
            into website traffic and user behavior. These services automatically
            collect certain non-personal information about your interactions
            with our site, such as your IP address, browser type, operating
            system, referring website, pages visited, and the date and time of
            your visit.
          </Information>

          <Header>6. Compliance with Laws</Header>

          <Information>
            MassGenerate may disclose user information if required to do so by
            law or in response to a valid legal request, such as a court order
            or subpoena.
          </Information>

          <Header>7. Changes to This Privacy Policy</Header>

          <Information>
            We reserve the right to update or modify this privacy policy at any
            time, without prior notice. Any changes made will be effective
            immediately upon posting the updated policy on the MassGenerate
            website. We encourage you to review this policy periodically to stay
            informed about how we protect your information.
          </Information>

          <Header>8. Contact Us</Header>

          <Information>
            If you have any questions, concerns, or inquiries regarding this
            privacy policy or the way we handle your information, please{" "}
            <Link to={"/contact"}>contact us</Link>
          </Information>

          <Information>
            By using MassGenerate, you acknowledge that you have read,
            understood, and agreed to this privacy policy.
          </Information>
        </InformationPage>
      </Page>
    </>
  );
};

export default memo(PrivacyPolicyPage);
