import TextInput from "components/textInput/TextInput";
import { memo } from "react";
import { FormControlProps, OverlayTrigger, Tooltip } from "react-bootstrap";
import { LockFill } from "react-bootstrap-icons";
import styled from "styled-components";

interface LockedTextInputProps extends FormControlProps {
  hasError: boolean | undefined;
  placeholder?: string | undefined;
  textarea?: boolean;
  onLockClick?: () => void;
}

const Wrapper = styled.div<{ cursor?: string }>`
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
    margin-left: 10px;
    cursor: ${(props) => props.cursor ?? "default"};
  }
`;

const StyledTooltip = styled(Tooltip)`
  position: fixed;
  > .tooltip-inner {
    color: white;
  }
`;

const tooltip = <StyledTooltip id="tooltip">Paid feature</StyledTooltip>;

const LockedTextInput = (props: LockedTextInputProps) => {
  const {
    hasError,
    value,
    onChange,
    onKeyUp,
    placeholder,
    textarea,
    className,
    name,
    id,
    disabled,
    onLockClick,
  } = props;

  return (
    <Wrapper cursor={onLockClick ? "pointer" : undefined}>
      <TextInput
        disabled={disabled}
        id={id}
        name={name}
        className={className}
        textarea={textarea}
        placeholder={placeholder}
        hasError={hasError}
        onKeyUp={onKeyUp}
        value={value}
        onChange={onChange}
      />
      {disabled && (
        <OverlayTrigger overlay={tooltip}>
          <LockFill
            onClick={() => {
              if (onLockClick) {
                onLockClick();
              }
            }}
          />
        </OverlayTrigger>
      )}
    </Wrapper>
  );
};

export default memo(LockedTextInput);
