import { memo } from "react";
import { Container, Form, Nav, Navbar as ReactNavbar } from "react-bootstrap";
import styled, { css } from "styled-components";
import { List, X } from "react-bootstrap-icons";
import { useAppDispatch, useAppSelector } from "hooks";
import uiSlice from "reducers/uiSlice";
import { RootState } from "store";
import { showingTopMenuSelector } from "selectors/uiSelectors";
import { Link, useLocation } from "react-router-dom";
import { tokenSelector } from "selectors/userSelectors";
import AvailbleCredits from "components/availableCredits/AvailableCredits";

const StyledNavbar = styled(ReactNavbar)`
  background: transparent;
  padding: 0;
  padding: 10px 0;
  z-index: 99;
`;

const SharedMenuIconStyles = css`
  color: white;
  cursor: pointer;
`;

const StyledMenu = styled(List)`
  ${SharedMenuIconStyles}
`;

const StyledClose = styled(X)`
  ${SharedMenuIconStyles}
`;

const Logo = styled.img`
  height: 20px;
  margin-left: 5px;
`;

const MenuIconHeight = 30;

const Navbar = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();

  const token = useAppSelector((state: RootState) => tokenSelector(state));

  const showingTopMenu = useAppSelector((state: RootState) =>
    showingTopMenuSelector(state)
  );

  const handleMenuToggle = (event: React.MouseEvent<SVGElement>) => {
    event.preventDefault();
    if (showingTopMenu) {
      dispatch(uiSlice.actions.hideTopMenu());
      return;
    }
    dispatch(uiSlice.actions.showTopMenu());
  };

  return (
    <StyledNavbar expand="lg">
      <Container fluid={true}>
        {location.pathname !== "/" && (
          <Link to={token === null ? "" : "/dashboard"}>
            <Logo alt="logo" src="/logo-small.png" />
          </Link>
        )}

        <Nav className="me-auto" navbarScroll></Nav>
        <Form className="d-flex">{!showingTopMenu && <AvailbleCredits />}</Form>
        <Form className="d-flex">
          {showingTopMenu && (
            <StyledClose size={MenuIconHeight} onClick={handleMenuToggle} />
          )}
          {!showingTopMenu && (
            <>
              <StyledMenu size={MenuIconHeight} onClick={handleMenuToggle} />
            </>
          )}
        </Form>
      </Container>
    </StyledNavbar>
  );
};

export default memo(Navbar);
