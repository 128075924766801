import { FC, ReactNode, memo } from "react";
import { Button, ButtonProps } from "react-bootstrap";
import styled from "styled-components";

interface PrimaryButtonProps extends ButtonProps {
  variant?: "solid" | "outline";
  children: ReactNode;
  disabled?: boolean;
  className?: any;
  onClick?: any;
}

const StyledButton: FC<ButtonProps> = styled(Button)<{ variant: string }>`
  display: flex;
  padding: 0 20px;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  background-color: ${(props) =>
    props.variant === "outline" ? "white" : "var(--primary-color)"};
  border-color: var(--primary-color);
  color: ${(props) =>
    props.variant === "solid" ? "white" : "var(--primary-color)"};
  width: 100%;

  &:focus,
  &:active,
  &:disabled,
  &:hover {
    border-color: var(--primary-color) !important;
    background-color: var(--primary-color) !important;
    color: white !important;
  }
`;

const PrimaryButton = (props: PrimaryButtonProps) => {
  const {
    children,
    className,
    disabled,
    onClick,
    variant = "solid",
    type = "submit",
  } = props;

  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      type={type}
      variant={variant}
      className={className}
    >
      {children}
    </StyledButton>
  );
};

export default memo(PrimaryButton);
