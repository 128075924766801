import Page from "components/page/Page";
import { memo } from "react";
import { Helmet } from "react-helmet";
import InformationPage from "components/informationPage/InformationPage";
import * as Yup from "yup";
import { Alert, Form, Spinner } from "react-bootstrap";
import emailSlice from "reducers/emailSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import PrimaryButton from "components/primaryButton/PrimaryButton";
import { RootState } from "store";
import styled from "styled-components";
import { Formik } from "formik";
import {
  sendContactErrorSelector,
  sendContactSuccessSelector,
  sendingContactSelector,
} from "selectors/emailSelectors";
import TextInput from "components/textInput/TextInput";
import Label from "components/label/Label";

const StyledForm = styled(Form)`
  padding-top: 5px;
  padding-bottom: 15px;
`;

const ContactPage = () => {
  const dispatch = useAppDispatch();

  const sendingContact = useAppSelector((state: RootState) =>
    sendingContactSelector(state)
  );

  const sendContactError = useAppSelector((state: RootState) =>
    sendContactErrorSelector(state)
  );

  const sendContactSuccess = useAppSelector((state: RootState) =>
    sendContactSuccessSelector(state)
  );

  const schema = Yup.object({
    email: Yup.string()
      .email("A valid email is required")
      .required("Email is a required field"),
    message: Yup.string().required("Message is a required field"),
  });

  const handleSubmit = (values: any) => {
    dispatch(emailSlice.actions.sendContact(values));
  };

  return (
    <>
      <Helmet>
        <title>MassGenerate - Contact</title>
      </Helmet>
      <Page>
        <InformationPage
          title="Contact"
          subtitle="We are here for you! How can we help?"
          maxWidth={768}
        >
          <Formik
            onSubmit={handleSubmit}
            validationSchema={schema}
            initialValues={{
              email: "",
              message: "",
            }}
          >
            {(props) => (
              <StyledForm onSubmit={props.handleSubmit} autoComplete="off">
                <Form.Group className="mb-3" controlId="email">
                  <Label>Email address</Label>
                  <TextInput
                    spellCheck={false}
                    hasError={
                      props.touched.email && props.errors.email !== undefined
                    }
                    value={props.values.email}
                    onChange={props.handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-4" controlId="message">
                  <Label>Message</Label>
                  <TextInput
                    hasError={
                      props.touched.message &&
                      props.errors.message !== undefined
                    }
                    textarea={true}
                    className="form-control"
                    id="message"
                    value={props.values.message}
                    onChange={props.handleChange}
                  />
                </Form.Group>
                {sendContactError && (
                  <Alert variant="danger">{sendContactError}</Alert>
                )}
                {sendContactSuccess && (
                  <Alert variant="success">
                    Your message has been sent. Thank you.
                  </Alert>
                )}
                {!sendContactSuccess && (
                  <PrimaryButton disabled={sendingContact}>
                    {!sendingContact && <span>Submit</span>}
                    {sendingContact && <Spinner size="sm" />}
                  </PrimaryButton>
                )}
              </StyledForm>
            )}
          </Formik>
        </InformationPage>
      </Page>
    </>
  );
};

export default memo(ContactPage);
