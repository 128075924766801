import PrimaryButton from "components/primaryButton/PrimaryButton";
import { memo, useEffect } from "react";
import styled from "styled-components";
import { Plus } from "react-bootstrap-icons";
import { useAppDispatch, useAppSelector } from "hooks";
import { RootState } from "store";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import contentSlice from "reducers/contentSlice";
import {
  createdContentIdSelector,
  creatingContentSelector,
} from "selectors/contentSelectors";

const Wrapper = styled.div`
  display: flex;
  justify-content: end;
  padding: 5px 15px 15px;

  > button {
    width: auto;
    svg {
      margin-left: -5px;
      margin-right: 5px;
    }
  }
`;

const DashboardControls = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleNewContent = () => {
    dispatch(contentSlice.actions.createContent());
  };

  const creatingContent = useAppSelector((state: RootState) =>
    creatingContentSelector(state)
  );

  const createdContentId = useAppSelector((state: RootState) =>
    createdContentIdSelector(state)
  );

  useEffect(() => {
    if (!createdContentId) return;

    navigate(`/create/${createdContentId}`);

    return () => {
      dispatch(contentSlice.actions.clearCreatedContentId());
    };
  }, [createdContentId, dispatch, navigate]);

  return (
    <Wrapper>
      <PrimaryButton onClick={handleNewContent}>
        {!creatingContent && (
          <>
            <Plus /> New Content
          </>
        )}
        {creatingContent && <Spinner size="sm" />}
      </PrimaryButton>
    </Wrapper>
  );
};

export default memo(DashboardControls);
