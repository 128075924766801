import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import CreateUserRequest from "types/createUserRequest";
import CreateUserResult from "types/createUserResult";
import MakePaymentRequest from "types/makePaymentRequest";
import SignInRequest from "types/signInRequest";
import SignInResult from "types/signInResult";
import User from "types/user";
import VerifyUserRequest from "types/verifyUserRequest";

interface UserState {
  token: string | null;
  creatingUser: boolean;
  createUserSuccess: boolean | null;
  createdUserEmail: string | null;
  verifyingUser: boolean;
  verifyUserSuccess: boolean | null;
  verifyUserError: string | null;
  signingIn: boolean;
  signInError: string | null;
  loadingUser: boolean;
  user: User | null;
  makingPayment: boolean;
  makePaymentSuccess: boolean | null;
  makePaymentError: string | null;
}

const initialState: UserState = {
  token: localStorage.getItem("jwt") ?? null,
  creatingUser: false,
  createUserSuccess: null,
  createdUserEmail: null,
  verifyingUser: false,
  verifyUserSuccess: null,
  verifyUserError: null,
  makingPayment: false,
  makePaymentSuccess: null,
  makePaymentError: null,
  signingIn: false,
  signInError: null,
  loadingUser: false,
  user: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    createUser: (
      state: UserState,
      action: PayloadAction<CreateUserRequest>
    ) => {
      state.creatingUser = true;
    },
    createUserSuccess: (
      state: UserState,
      action: PayloadAction<CreateUserResult>
    ) => {
      state.creatingUser = false;
      state.createUserSuccess = true;
      state.createdUserEmail = action.payload.email;
    },
    createUserFailure: (state: UserState) => {
      state.creatingUser = false;
      state.createUserSuccess = false;
    },
    signOut: (state: UserState) => {
      localStorage.removeItem("jwt");
      state.token = null;
      state.user = null;
    },
    signIn: (state: UserState, action: PayloadAction<SignInRequest>) => {
      state.signingIn = true;
      state.signInError = null;
    },
    signInSuccess: (state: UserState, action: PayloadAction<SignInResult>) => {
      state.signingIn = false;
      localStorage.setItem("jwt", action.payload.token);
      state.token = action.payload.token;
    },
    signInFailure: (state: UserState, action: PayloadAction<string>) => {
      state.signingIn = false;
      state.signInError = action.payload;
    },
    clearSignInFailure: (state: UserState) => {
      state.signInError = null;
    },
    verifyUser: (
      state: UserState,
      action: PayloadAction<VerifyUserRequest>
    ) => {
      state.verifyingUser = true;
      state.verifyUserSuccess = false;
      state.verifyUserError = null;
    },
    verifyUserSuccess: (state: UserState) => {
      state.verifyingUser = false;
      state.verifyUserSuccess = true;
      state.verifyUserError = null;
    },
    verifyUserFailure: (state: UserState, action: PayloadAction<string>) => {
      state.verifyingUser = false;
      state.verifyUserSuccess = false;
      state.verifyUserError = action.payload;
    },
    makePayment: (
      state: UserState,
      action: PayloadAction<MakePaymentRequest>
    ) => {
      state.makingPayment = true;
      state.makePaymentSuccess = false;
      state.makePaymentError = null;
    },
    makePaymentSuccess: (state: UserState) => {
      state.makingPayment = false;
      state.makePaymentSuccess = true;
      state.makePaymentError = null;
    },
    makePaymentFailure: (state: UserState, action: PayloadAction<string>) => {
      state.makingPayment = false;
      state.makePaymentSuccess = false;
      state.makePaymentError = action.payload;
    },
    clearMakePaymentSuccess: (state: UserState) => {
      state.makingPayment = false;
      state.makePaymentSuccess = false;
      state.makePaymentError = null;
    },
    loadUser: (state: UserState) => {
      state.loadingUser = true;
      state.user = null;
    },
    loadUserSuccess: (state: UserState, action: PayloadAction<User>) => {
      state.loadingUser = false;
      state.user = action.payload;
    },
    loadUserFailure: (state: UserState, action: PayloadAction<string>) => {
      state.loadingUser = false;
      state.user = null;
    },
  },
});

export const {
  makePaymentSuccess,
  makePaymentFailure,
  createUser,
  createUserSuccess,
  createUserFailure,
  verifyUser,
  verifyUserSuccess,
  verifyUserFailure,
  signInSuccess,
  signInFailure,
  loadUserSuccess,
  loadUserFailure,
} = userSlice.actions;

export default userSlice;
