// @ts-ignore
import { toastr } from "react-redux-toastr";

import { put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import HttpResponse from "types/httpResponse";
import contentSlice, {
  createContentFailure,
  createContentSuccess,
  estimateGenerationFailure,
  estimateGenerationSuccess,
  generateContentFailure,
  generateContentSuccess,
  loadAllContentFailure,
  loadAllContentSuccess,
  loadContentFailure,
  loadContentSuccess,
  saveContentFailure,
  saveContentSuccess,
} from "reducers/contentSlice";
import LoadAllContentResult from "types/loadAllContentResponse";
import EstimateGenerationRequest from "types/estimateGenerationRequest";
import EstimateGenerationResponse from "types/estimateGenerationResponse";
import { PayloadAction } from "@reduxjs/toolkit";
import GenerateContentRequest from "types/generateContentRequest";
import SaveContentRequest from "types/saveContentRequest";
import CreateContentResult from "types/createContentResult";
import LoadContentRequest from "types/loadContentRequest";
import LoadContentResult from "types/loadContentResult";

function* loadAllContentSaga() {
  try {
    const loadContentAllResponse: HttpResponse<LoadAllContentResult> =
      yield axios.get(`/content`);
    yield put(loadAllContentSuccess(loadContentAllResponse.data));
  } catch (error: any) {
    toastr.error("Error Loading Content", error.message);
    yield put(loadAllContentFailure(error.message));
  }
}

function* estimateGenerationSaga(
  action: PayloadAction<EstimateGenerationRequest>
) {
  try {
    const estimateGenerationResponse: HttpResponse<EstimateGenerationResponse> =
      yield axios.post(`/content/estimate`, action.payload);
    yield put(estimateGenerationSuccess(estimateGenerationResponse.data));
  } catch (error: any) {
    toastr.error("Error Estimating Generation", error.message);
    yield put(estimateGenerationFailure(error.message));
  }
}

function* generateContentSaga(action: PayloadAction<GenerateContentRequest>) {
  try {
    yield axios.post(`/content/${action.payload.contentId}/generate`);
    yield put(generateContentSuccess());
  } catch (error: any) {
    toastr.error("Error Generating Content", error.message);
    yield put(generateContentFailure(error.message));
  }
}

function* createContentSaga() {
  try {
    const response: HttpResponse<CreateContentResult> = yield axios.post(
      `/content`
    );
    yield put(createContentSuccess(response.data));
  } catch (error: any) {
    toastr.error("Error Creating Content", error.message);
    yield put(createContentFailure(error.message));
  }
}

function* saveContentSaga(action: PayloadAction<SaveContentRequest>) {
  try {
    yield axios.put(`/content`, action.payload);
    yield put(saveContentSuccess());
  } catch (error: any) {
    toastr.error("Error Saving Content", error.message);
    yield put(saveContentFailure(error.message));
  }
}

function* loadContentSaga(action: PayloadAction<LoadContentRequest>) {
  try {
    const loadContentResponse: HttpResponse<LoadContentResult> =
      yield axios.get(`/content/${action.payload.contentId}`);
    yield put(
      loadContentSuccess({
        ...loadContentResponse.data,
        contentId: action.payload.contentId,
      })
    );
  } catch (error: any) {
    toastr.error("Error Loading Content", error.message);
    yield put(loadContentFailure(error.message));
  }
}

function* contentSagas() {
  yield takeLatest(contentSlice.actions.createContent, createContentSaga);
  yield takeLatest(contentSlice.actions.saveContent, saveContentSaga);
  yield takeLatest(contentSlice.actions.loadContent, loadContentSaga);
  yield takeLatest(contentSlice.actions.loadAllContent, loadAllContentSaga);
  yield takeLatest(
    contentSlice.actions.estimateGeneration,
    estimateGenerationSaga
  );
  yield takeLatest(contentSlice.actions.generateContent, generateContentSaga);
}

export default contentSagas;
