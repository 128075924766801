import { Link as ReactLink } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";
import styled from "styled-components";
import PrimaryButton from "components/primaryButton/PrimaryButton";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { ChangeEvent, FormEvent } from "react";
import Notice from "components/notice/Notice";

const TermsAgree = styled(Form.Check)<{ $hasError: boolean | undefined }>`
  margin-top: 30px;

  a {
    color: var(--bs-link-hover-color-rgb);
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  ${(props) =>
    props.$hasError
      ? "input { border-color: red; } label, a { color: red; } a { text-decoration: underline;}"
      : null}
`;

interface AddCreditsProps {
  onPayment: (e: FormEvent<HTMLFormElement>, confirmationToken: string) => void;
  chargeUsd: number;
  handleAgreeChange?: (e: string | ChangeEvent<any>) => void;
  agreeError?: boolean;
  agreeValue?: string;
  makingPayment: boolean;
  paymentError?: string | null;
}

const Payment = (props: AddCreditsProps) => {
  const {
    onPayment,
    chargeUsd,
    handleAgreeChange,
    agreeError,
    agreeValue,
    makingPayment,
    paymentError,
  } = props;

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      return;
    }

    const { error, confirmationToken } = await stripe.createConfirmationToken({
      elements,
    });

    if (error) {
      return;
    }

    onPayment(event, confirmationToken.id);
  };

  return (
    <Form onSubmit={handleSubmit} autoComplete="off">
      <Form.Group className="mb-4">
        <PaymentElement />
      </Form.Group>

      <Form.Group className="mb-4" controlId="agree">
        <TermsAgree
          $hasError={agreeError}
          onChange={(event: ChangeEvent<any>) => {
            if (handleAgreeChange) {
              handleAgreeChange(event);
            }
          }}
          value={agreeValue}
          id="agree"
          name="agree"
          label={
            <>
              I agree to be charged ${chargeUsd}USD. I understand the credits
              are non refundable and subject to our{" "}
              <ReactLink target="_blank" to={"/terms-and-conditions"}>
                Terms and Conditions
              </ReactLink>{" "}
            </>
          }
        />
      </Form.Group>

      {!makingPayment && paymentError && (
        <Notice type="error">{paymentError}</Notice>
      )}

      <PrimaryButton disabled={makingPayment}>
        {!makingPayment && <span>Pay Now</span>}
        {makingPayment && <Spinner size="sm" />}
      </PrimaryButton>
    </Form>
  );
};

export default Payment;
