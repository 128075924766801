import axios from "axios";
import { Outlet } from "react-router-dom";
import "./App.css";
import Navbar from "components/navbar/Navbar";
import TopMenu from "components/topMenu/TopMenu";
import SignInMenu from "components/signInMenu/SignInMenu";
import userSlice from "reducers/userSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import { RootState } from "store";
import { tokenSelector } from "selectors/userSelectors";
import { useEffect } from "react";
import AddCredits from "components/addCredits/AddCredits";

axios.defaults.baseURL = process.env["REACT_APP_API_BASE_URL"];

const SetUpAxiosInterceptors = (dispatch: any) => {
  axios.interceptors.request.use(function (request: any) {
    const jwt = localStorage.getItem("jwt");
    if (jwt) {
      request.headers["authorization"] = `Bearer ${jwt}`;
    }
    return request;
  });
  axios.interceptors.response.use(
    function (response: any) {
      return response;
    },
    function (error: any) {
      if (error.response && error.response.status === 401) {
        dispatch(userSlice.actions.signOut());
      }
      if (error.response && error.response.status === 400) {
        let errorMessages = [];
        for (const property in error.response.data.errors) {
          errorMessages.push(error.response.data.errors[property][0]);
        }
        if (errorMessages.length > 0)
          error.message = errorMessages.join("\r\n");
        else if (error.response.data.detail)
          error.message = error.response.data.detail;
      }
      return Promise.reject(error);
    }
  );
};

function App() {
  const dispatch = useAppDispatch();

  SetUpAxiosInterceptors(dispatch);

  const jwt = useAppSelector((state: RootState) => tokenSelector(state));

  useEffect(() => {
    if (jwt) {
      dispatch(userSlice.actions.loadUser());
    }
  }, [jwt, dispatch]);

  return (
    <div className="App">
      <Navbar />
      <Outlet />
      <TopMenu />
      <SignInMenu />
      <AddCredits />
    </div>
  );
}
export default App;
