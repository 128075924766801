import { memo, useEffect } from "react";
import Page from "components/page/Page";
import { Helmet } from "react-helmet";
import { styled } from "styled-components";
import { useAppDispatch, useAppSelector } from "hooks";
import { RootState } from "store";
import { tokenSelector } from "selectors/userSelectors";
import uiSlice from "reducers/uiSlice";
import { showingTopMenuSelector } from "selectors/uiSelectors";
import DashboardControls from "components/dashboardControls/DashboardControls";
import ContentList from "components/contentList/ContentList";
import Scrollbars from "rc-scrollbars";

const StyledPage = styled(Page)``;

const DashboardPage = () => {
  const dispatch = useAppDispatch();

  const token = useAppSelector((state: RootState) => tokenSelector(state));

  const showingTopMenu = useAppSelector((state: RootState) =>
    showingTopMenuSelector(state)
  );

  useEffect(() => {
    if (token === null && !showingTopMenu) {
      dispatch(uiSlice.actions.showSignInMenu());
    }
  }, [token, dispatch, showingTopMenu]);

  return (
    <>
      <Helmet>
        <title>MassGenerate - Dashboard</title>‍
      </Helmet>
      <StyledPage fixed={true}>
        <DashboardControls />
        <Scrollbars>
          <ContentList />
        </Scrollbars>
      </StyledPage>
    </>
  );
};

export default memo(DashboardPage);
