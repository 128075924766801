import { memo } from "react";
import Page from "components/page/Page";
import { Helmet } from "react-helmet";
import GetStarted from "components/getStarted/GetStarted";
import Features from "components/features/Features";

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>
          MassGenerate - Generate intelligent, targeted and highly converting
          content
        </title>
        ‍
        <meta
          name="description"
          content="A platform allowing content generation with accurate targeting of keywords and topics to create highly converting SEO content with the power of AI"
        />
      </Helmet>
      <Page>
        <GetStarted />
      </Page>
      <Features />
    </>
  );
};

export default memo(HomePage);
