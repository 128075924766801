import PrimaryButton from "components/primaryButton/PrimaryButton";
import { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import styled from "styled-components";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "hooks";
import { RootState } from "store";
import { tokenSelector } from "selectors/userSelectors";
import { useNavigate } from "react-router-dom";
import contentSlice from "reducers/contentSlice";
import {
  createdContentIdSelector,
  creatingContentSelector,
} from "selectors/contentSelectors";

const Content = styled.div`
  z-index: 2;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 50px;
`;

const StyledContainer = styled(Container)`
  display: flex;
  min-height: 100vh;

  > video {
    position: absolute;
    bottom: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 0;
    opacity: 0.2;
  }
`;

const Logo = styled.img`
  height: 180px;
  margin-bottom: 60px;

  @media only screen and (max-width: 575px) {
    height: 100px;
  }
`;

const StyledGetStartedButton = styled(PrimaryButton)`
  width: auto;
  padding: 0 40px;
  font-size: var(--font-2);
  margin-top: 25px;
  height: 70px;

  @media only screen and (max-width: 575px) {
    font-size: var(--font-1);
  }
`;

const StyledH1 = styled.h1`
  margin-bottom: 25px;
  font-size: var(--font-3);
  text-align: center;

  @media only screen and (max-width: 575px) {
    font-size: var(--font-2);
  }
`;

const epochSeedNow = 1711060101;
const populationSeed = 8098249981;
const birthsPerSecond = 4.3;

const GetStarted = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const token = useAppSelector((state: RootState) => tokenSelector(state));

  const creatingContent = useAppSelector((state: RootState) =>
    creatingContentSelector(state)
  );

  const createdContentId = useAppSelector((state: RootState) =>
    createdContentIdSelector(state)
  );

  const getPopulate = () => {
    var currentEpoch = moment().unix();
    return populationSeed + (currentEpoch - epochSeedNow) * birthsPerSecond;
  };

  const [population, setPopulation] = useState(getPopulate());

  const handleGetStarted = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(contentSlice.actions.createContent());
  };

  const handleGoToDashboard = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    if (!createdContentId) return;

    navigate(`/create/${createdContentId}`);

    return () => {
      dispatch(contentSlice.actions.clearCreatedContentId());
    };
  }, [createdContentId, dispatch, navigate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPopulation(getPopulate());
    }, 1000);
    return () => clearTimeout(timer);
  });
  return (
    <StyledContainer fluid={true}>
      <video autoPlay={true} loop muted>
        <source src="people2.mp4" type="video/mp4" />
      </video>
      <Content>
        <Logo alt="logo" src="logo.png" />
        <StyledH1>
          There are an estimated {Math.round(population).toLocaleString()}{" "}
          people on the planet.
        </StyledH1>
        <StyledH1>
          Generate intelligent, targeted and highly converting content to reach
          the masses.
        </StyledH1>
        {token === null && (
          <StyledGetStartedButton type="button" onClick={handleGetStarted}>
            {!creatingContent && <>Get Started</>}
            {creatingContent && <Spinner />}
          </StyledGetStartedButton>
        )}
        {token !== null && (
          <StyledGetStartedButton type="button" onClick={handleGoToDashboard}>
            Go To Dashboard
          </StyledGetStartedButton>
        )}
      </Content>
    </StyledContainer>
  );
};

export default GetStarted;
