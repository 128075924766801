import Label from "components/label/Label";
import LockedTextInput from "components/lockedTextInput/LockedTextInput";
import PrimaryButton from "components/primaryButton/PrimaryButton";
import TextInput from "components/textInput/TextInput";
import TextInputList from "components/textInputList/TextInputList";
import { FieldArray, Formik } from "formik";
import { useAppDispatch, useAppSelector } from "hooks";
import Scrollbars from "rc-scrollbars";
import { memo, MouseEventHandler, useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import contentSlice from "reducers/contentSlice";
import uiSlice from "reducers/uiSlice";
import userSlice from "reducers/userSlice";
import {
  estimatedGenerationCostTokensSelector,
  estimatingGenerationSelector,
  generateContentSuccessSelector,
  generatingContentSelector,
  savingContentSelector,
} from "selectors/contentSelectors";
import { userSelector } from "selectors/userSelectors";
import { RootState } from "store";
import styled from "styled-components";
import Content from "types/content";
import * as Yup from "yup";

const Wrapper = styled.div`
  width: 400px;
  position: relative;
  background: var(--secondary-bg);

  @media only screen and (max-width: 992px) {
    width: 300px;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 20px 30px;
    min-height: 50%;
  }
`;

const Scrollable = styled.div`
  position: absolute;
  top: 0;
  bottom: 90px;
  left: 0;
  right: 0;

  .rc-scrollbars-view {
    padding: 20px 30px 20px 30px;
  }
`;

const GenerateButton = styled(PrimaryButton)`
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
  width: auto;
`;

interface CreateSideMenuProps {
  contentId: string;
  content?: Content | null;
}

const CreateSideMenu = (props: CreateSideMenuProps) => {
  const { contentId, content } = props;

  const [lastSavedContent, setLastSavedContent] = useState();

  const [isDirty, setIsDirty] = useState(true);

  const dispatch = useAppDispatch();

  const user = useAppSelector((state: RootState) => userSelector(state));

  const savingContent = useAppSelector((state: RootState) =>
    savingContentSelector(state)
  );

  const estimatingGeneration = useAppSelector((state: RootState) =>
    estimatingGenerationSelector(state)
  );

  const generatingContent = useAppSelector((state: RootState) =>
    generatingContentSelector(state)
  );

  const generateContentSuccess = useAppSelector((state: RootState) =>
    generateContentSuccessSelector(state)
  );

  const estimatedGenerationCostTokens = useAppSelector((state: RootState) =>
    estimatedGenerationCostTokensSelector(state)
  );

  useEffect(() => {
    if (generateContentSuccess) {
      setIsDirty(false);
      dispatch(contentSlice.actions.clearGenerateContentSuccess());
      dispatch(contentSlice.actions.loadContent({ contentId }));
      dispatch(userSlice.actions.loadUser());
    }
  }, [contentId, dispatch, generateContentSuccess]);

  const schema = Yup.object({
    topic: Yup.string().required("Topic is a required field"),
    maxLength: Yup.number().required("Max Length is a required field"),
    keywords: Yup.array().of(
      Yup.string().required("Keyword value cannot be empty")
    ),
  });

  let initialValues = {
    topic: content?.topic ?? "",
    maxLength: content?.maxLength ?? 500,
    conversionGoal: content?.conversionGoal ?? "",
    keywords:
      content?.keywords && content?.keywords.length > 0
        ? content?.keywords
        : [""],
  };

  useEffect(() => {
    if (!content) return;

    if (!user) return;

    schema
      .validate(initialValues)
      .then(() => {
        dispatch(contentSlice.actions.estimateGeneration(content));
      })
      .catch(() => {});
  }, [dispatch, content]);

  const handleSubmit = (values: any) => {
    if (!user) {
      dispatch(
        contentSlice.actions.saveContent({
          ...values,
          contentId,
          content: content?.content,
        })
      );
      dispatch(uiSlice.actions.showSignUp());
      return;
    }
    dispatch(contentSlice.actions.generateContent({ contentId }));
  };

  const getGenerateLabel = () => {
    return estimatedGenerationCostTokens && estimatedGenerationCostTokens > 0
      ? `Generate ( ${estimatedGenerationCostTokens} tokens )`
      : "Generate";
  };

  const [t, setT] = useState<any>(null);

  const handleKeyUp = (isValid: boolean, values: any) => {
    if (!user) {
      return;
    }
    if (t) clearTimeout(t);
    setT(
      setTimeout(() => {
        const oldContent = lastSavedContent || content;
        const newContent = { ...values, contentId, content: content?.content };

        const isDirty = !(
          newContent.topic === oldContent?.topic &&
          newContent.maxLength === oldContent?.maxLength &&
          newContent.conversionGoal === oldContent?.conversionGoal &&
          JSON.stringify(newContent.keywords) ===
            JSON.stringify(oldContent?.keywords)
        );
        setIsDirty(isDirty);

        if (isDirty) {
          dispatch(contentSlice.actions.saveContent(newContent));
          setLastSavedContent(newContent);

          if (!isValid) {
            dispatch(contentSlice.actions.clearEstimatedGenerationCostTokens());
            return;
          }
          dispatch(contentSlice.actions.estimateGeneration(values));
        }
      }, 750)
    );
  };

  const handleUpgradeClick = () => {
    dispatch(uiSlice.actions.showAddCredits());
  };

  const maxKeywords = Number(
    user?.freeUser !== false
      ? process.env["REACT_APP_MAX_KEYWORDS_FREE"]
      : process.env["REACT_APP_MAX_KEYWORDS_PAID"]
  );

  return (
    <Wrapper>
      {content && (
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit} autoComplete="off">
              <Scrollable>
                <Scrollbars>
                  <Form.Group className="mb-3" controlId="topic">
                    <Label>Topic</Label>
                    <TextInput
                      textarea={true}
                      id="topic"
                      className="form-control"
                      hasError={
                        props.touched.topic && props.errors.topic !== undefined
                      }
                      value={props.values.topic}
                      onKeyUp={(e) => {
                        handleKeyUp(props.isValid, props.values);
                      }}
                      onChange={props.handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Label>
                      Keywords{!user?.freeUser ? ` (Max ${maxKeywords})` : null}
                    </Label>
                    <FieldArray
                      name="keywords"
                      render={(arrayHelpers: any) => {
                        return (
                          <TextInputList
                            max={maxKeywords}
                            onAdd={() => {
                              arrayHelpers.insert(
                                props.values.keywords.length,
                                ""
                              );
                              handleKeyUp(props.isValid, props.values);
                            }}
                            onRemove={(index: number) => {
                              arrayHelpers.remove(index);
                              handleKeyUp(props.isValid, props.values);
                            }}
                            values={props.values.keywords}
                            hasError={
                              props.touched.keywords &&
                              props.errors.keywords !== undefined
                            }
                            onKeyUp={(e) => {
                              handleKeyUp(props.isValid, props.values);
                            }}
                            name="keywords"
                            id="keywords"
                            onChange={props.handleChange}
                          />
                        );
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="maxLength">
                    <Label>Max Length (Words)</Label>
                    <LockedTextInput
                      onLockClick={user ? handleUpgradeClick : undefined}
                      hasError={
                        props.touched.maxLength &&
                        props.errors.maxLength !== undefined
                      }
                      onKeyUp={(e) => {
                        handleKeyUp(props.isValid, props.values);
                      }}
                      value={props.values.maxLength}
                      onChange={props.handleChange}
                      disabled={!user || user.freeUser === true}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="conversionGoal">
                    <Label>Conversion Goal</Label>
                    <LockedTextInput
                      onLockClick={user ? handleUpgradeClick : undefined}
                      hasError={
                        props.touched.conversionGoal &&
                        props.errors.conversionGoal !== undefined
                      }
                      onKeyUp={(e) => {
                        handleKeyUp(props.isValid, props.values);
                      }}
                      value={props.values.conversionGoal}
                      onChange={props.handleChange}
                      disabled={!user || user.freeUser === true}
                    />
                  </Form.Group>
                </Scrollbars>
              </Scrollable>

              <GenerateButton
                disabled={
                  savingContent ||
                  estimatingGeneration ||
                  generatingContent ||
                  !isDirty
                }
              >
                {!generatingContent &&
                  !savingContent &&
                  !estimatingGeneration && <span>{getGenerateLabel()}</span>}
                {(generatingContent ||
                  savingContent ||
                  estimatingGeneration) && <Spinner size="sm" />}
              </GenerateButton>
            </Form>
          )}
        </Formik>
      )}
    </Wrapper>
  );
};

export default memo(CreateSideMenu);
