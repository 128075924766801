import InformationPage from "components/informationPage/InformationPage";
import Page from "components/page/Page";
import { useAppDispatch, useAppSelector } from "hooks";
import { memo, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import userSlice from "reducers/userSlice";
import {
  verifyUserErrorSelector,
  verifyUserSuccessSelector,
  verifyingUserSelector,
} from "selectors/userSelectors";
import { RootState } from "store";
import Notice from "components/notice/Notice";

const VerifyPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { token } = useParams();

  const [tokenError, setTokenError] = useState<string | undefined>();

  const verifyingUser = useAppSelector((state: RootState) =>
    verifyingUserSelector(state)
  );

  const verifyUserSuccess = useAppSelector((state: RootState) =>
    verifyUserSuccessSelector(state)
  );

  const verifyUserError = useAppSelector((state: RootState) =>
    verifyUserErrorSelector(state)
  );

  useEffect(() => {
    if (verifyUserSuccess) {
      navigate("/dashboard");
    }
  }, [navigate, verifyUserSuccess]);

  useEffect(() => {
    if (token) {
      dispatch(userSlice.actions.verifyUser({ token: token }));
    } else {
      setTokenError("Token Missing");
    }
  }, [token, dispatch]);

  return (
    <>
      <Helmet>
        <title>MassGenerate - Verifying Email</title>
      </Helmet>
      <Page>
        <InformationPage maxWidth={768}>
          {tokenError && <Notice type="error">{tokenError}</Notice>}
          {verifyUserError && <Notice type="error">{verifyUserError}</Notice>}
          {verifyingUser && <Notice type="busy">Please Wait</Notice>}
        </InformationPage>
      </Page>
    </>
  );
};

export default memo(VerifyPage);
