import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Content from "types/content";
import ContentBase from "types/contentBase";
import CreateContentResult from "types/createContentResult";
import EstimateGenerationRequest from "types/estimateGenerationRequest";
import EstimateGenerationResponse from "types/estimateGenerationResponse";
import GenerateContentRequest from "types/generateContentRequest";
import LoadAllContentResult from "types/loadAllContentResponse";
import LoadContentRequest from "types/loadContentRequest";
import LoadContentResult from "types/loadContentResult";
import SaveContentRequest from "types/saveContentRequest";

interface ContentState {
  loadingAllContent: boolean;
  allContent: Array<ContentBase> | undefined;
  estimatingGeneration: boolean;
  estimatedGenerationCostTokens: number | undefined;
  generatingContent: boolean;
  generateContentSuccess: boolean;
  creatingContent: boolean;
  savingContent: boolean;
  loadingContent: boolean;
  createdContentId: string | null;
  loadContentSuccess: boolean | null;
  content: Content | null;
}

const initialState: ContentState = {
  loadingAllContent: false,
  allContent: undefined,
  estimatingGeneration: false,
  estimatedGenerationCostTokens: undefined,
  generatingContent: false,
  generateContentSuccess: false,
  creatingContent: false,
  savingContent: false,
  loadingContent: false,
  createdContentId: null,
  loadContentSuccess: null,
  content: null,
};

const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    loadAllContent: (state: ContentState) => {
      state.loadingAllContent = true;
      state.allContent = undefined;
    },
    loadAllContentSuccess: (
      state: ContentState,
      action: PayloadAction<LoadAllContentResult>
    ) => {
      state.loadingAllContent = false;
      state.allContent = action.payload.content;
    },
    loadAllContentFailure: (state: ContentState) => {
      state.loadingAllContent = false;
      state.allContent = undefined;
    },
    clearEstimatedGenerationCostTokens: (state: ContentState) => {
      state.estimatedGenerationCostTokens = undefined;
    },
    estimateGeneration: (
      state: ContentState,
      action: PayloadAction<EstimateGenerationRequest>
    ) => {
      state.estimatingGeneration = true;
      state.estimatedGenerationCostTokens = undefined;
    },
    estimateGenerationSuccess: (
      state: ContentState,
      action: PayloadAction<EstimateGenerationResponse>
    ) => {
      state.estimatingGeneration = false;
      state.estimatedGenerationCostTokens =
        action.payload.estimatedGenerationCostTokens;
    },
    estimateGenerationFailure: (state: ContentState) => {
      state.estimatingGeneration = false;
      state.estimatedGenerationCostTokens = undefined;
    },
    generateContent: (
      state: ContentState,
      action: PayloadAction<GenerateContentRequest>
    ) => {
      state.generatingContent = true;
      state.generateContentSuccess = false;
    },
    generateContentSuccess: (state: ContentState) => {
      state.generatingContent = false;
      state.generateContentSuccess = true;
    },
    generateContentFailure: (state: ContentState) => {
      state.generatingContent = false;
      state.generateContentSuccess = false;
    },
    clearGenerateContentSuccess: (state: ContentState) => {
      state.generateContentSuccess = false;
    },
    createContent: (state: ContentState) => {
      state.creatingContent = true;
      state.createdContentId = null;
    },
    loadContent: (
      state: ContentState,
      action: PayloadAction<LoadContentRequest>
    ) => {
      state.loadingContent = true;
      state.loadContentSuccess = null;
    },
    loadContentSuccess: (
      state: ContentState,
      action: PayloadAction<LoadContentResult>
    ) => {
      state.loadingContent = false;
      state.loadContentSuccess = true;
      state.content = action.payload;
    },
    loadContentFailure: (state: ContentState) => {
      state.loadingContent = false;
      state.loadContentSuccess = false;
    },
    saveContent: (
      state: ContentState,
      action: PayloadAction<SaveContentRequest>
    ) => {
      state.savingContent = true;
      state.content = action.payload;
    },
    saveContentSuccess: (state: ContentState) => {
      state.savingContent = false;
    },
    saveContentFailure: (state: ContentState) => {
      state.savingContent = false;
    },
    clearCreatedContentId: (state: ContentState) => {
      state.createdContentId = null;
    },
    clearContent: (state: ContentState) => {
      state.content = null;
    },
    createContentSuccess: (
      state: ContentState,
      action: PayloadAction<CreateContentResult>
    ) => {
      state.creatingContent = false;
      state.createdContentId = action.payload.contentId;
    },
    createContentFailure: (state: ContentState) => {
      state.creatingContent = false;
    },
  },
});

export const {
  loadAllContentSuccess,
  loadAllContentFailure,
  estimateGenerationSuccess,
  estimateGenerationFailure,
  generateContentSuccess,
  generateContentFailure,
  createContentSuccess,
  createContentFailure,
  saveContentSuccess,
  saveContentFailure,
  loadContentSuccess,
  loadContentFailure,
} = contentSlice.actions;

export default contentSlice;
