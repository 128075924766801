import { RootState } from "../store";

export const loadingAllContentSelector = (state: RootState) =>
  state.content.loadingAllContent;

export const allContentSelector = (state: RootState) =>
  state.content.allContent;

export const estimatingGenerationSelector = (state: RootState) =>
  state.content.estimatingGeneration;

export const estimatedGenerationCostTokensSelector = (state: RootState) =>
  state.content.estimatedGenerationCostTokens;

export const generatingContentSelector = (state: RootState) =>
  state.content.generatingContent;

export const generateContentSuccessSelector = (state: RootState) =>
  state.content.generateContentSuccess;

export const creatingContentSelector = (state: RootState) =>
  state.content.creatingContent;

export const createdContentIdSelector = (state: RootState) =>
  state.content.createdContentId;

export const savingContentSelector = (state: RootState) =>
  state.content.savingContent;

export const contentSelector = (state: RootState) => state.content.content;

export const loadingContentSelector = (state: RootState) =>
  state.content.loadingContent;
