import { useWindowWidth } from "@react-hook/window-size";
import Feature from "components/feature/Feature";
import { memo } from "react";
import { Container } from "react-bootstrap";
import { Crosshair, EmojiSmile, UiChecks } from "react-bootstrap-icons";
import { styled } from "styled-components";

const Wrapper = styled(Container)`
  max-width: 768px;
`;

const Features = () => {
  const onlyWidth = useWindowWidth();

  console.log(onlyWidth);

  return (
    <Wrapper>
      <Feature title="Targeted Keywords" Icon={Crosshair}>
        Naturally utilize targeted keywords to avoid search engine penalties.
      </Feature>
      <Feature
        align={onlyWidth >= 576 ? "right" : "left"}
        title="Conversion Goals"
        Icon={UiChecks}
      >
        Include an optional goal in generated content to optimize reader
        conversion.
      </Feature>
      <Feature title="Transparent Pricing" Icon={EmojiSmile}>
        Credit usage estimated up front to help track generation activity costs.
      </Feature>
    </Wrapper>
  );
};

export default memo(Features);
