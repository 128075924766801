import { ReactNode, memo } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";

const StyledH1 = styled.h1`
  align-items: center;
  font-size: var(--font-4);
  margin: 0;
  margin-bottom: 50px;
  margin-top: 30px;

  @media only screen and (max-width: 767px) {
    text-align: center;
  }
`;

const StyledH2 = styled.h2`
  align-items: center;
  font-size: var(--font-2);
  margin: 0;
  margin-bottom: 50px;

  @media only screen and (max-width: 767px) {
    font-size: var(--font-1);
    padding: 0 10px;
    text-align: center;
  }
`;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const StyledContainer = styled(Container)<{ $maxWidth: number }>`
  ${(props) => `max-width: ${props.$maxWidth}px;`}

  @media only screen and (${(props) => `max-width: ${props.$maxWidth}px`}) {
    padding: 0 !important;
  }
`;

const Card = styled.div<{ $maxWidth: number }>`
  background: var(--secondary-bg);
  padding: 25px 40px;
  border-radius: 5px;
  margin-bottom: 50px;

  a {
    text-decoration: none;
    color: var(--bs-link-color-rgb);
  }

  a:hover {
    color: var(--bs-link-hover-color-rgb);
    text-decoration: underline;
  }

  @media only screen and (${(props) => `max-width: ${props.$maxWidth}px`}) {
    border-radius: 0;
    margin-bottom: 0;
  }
`;

interface InformationPageProps {
  title?: string;
  subtitle?: string;
  children: ReactNode;
  maxWidth?: number;
}

const InformationPage = (props: InformationPageProps) => {
  const { children, title, maxWidth = 767, subtitle } = props;

  return (
    <Wrapper>
      <StyledContainer $maxWidth={maxWidth}>
        {title && <StyledH1>{title}</StyledH1>}
        <StyledH2>{subtitle}</StyledH2>
        <Card $maxWidth={maxWidth}>{children}</Card>
      </StyledContainer>
    </Wrapper>
  );
};

export default memo(InformationPage);
